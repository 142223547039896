<template>
  <ion-page>

    <ion-content class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonSpinner } from '@ionic/vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Login',
  data () {
    return {
      inserat: null,
      userMail: null,
      userPassword: null,
      isLoading: true,
    }
  },
  components: {
    IonContent,
    IonPage,
    IonSpinner
  },
  mounted() {

    // clear
    localStorage.clear();

    // clear
    window.dispatchEvent(new CustomEvent('user-state-changed', {
      detail: {
        userId: null,
        userName: null,
        userToken: null,
        userIsLoggedIn: false
      }
    }));

    // redirect to main screen
    this.$router.push({ path: '/login' })

  }
});

</script>

<style scoped>

ion-spinner {
  display: block;
  margin: auto;
  margin-top: 50%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

</style>